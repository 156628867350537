// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DomainIcon from "@mui/icons-material/Domain";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { PageLoading } from "../../../components/page_loading";

export const OrganizationNameComponent = (props: {
  loading: boolean;
  setOrganizationName: (organizationName: string) => Promise<void>;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
}) => {
  const {
    loading,
    setOrganizationName: storeOrganizationName,
    // navigateToNext,
    // navigateToPrevious,
  } = props;

  const [organizationName, setOrganizationName] = useState<string>("");

  const validId =
    organizationName === "" || /^[a-z,A-Z,0-9, ]+$/.test(organizationName);
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Tell us about your organization
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            Secure environments and services - the "building blocks" in your AWS
            - are associated with organizations.
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            Please give us the name of your organization, we will use it for
            identification.
          </Typography>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>The name of your organization</FormLabel>
            <Stack spacing={0.5}>
              <Input
                startDecorator={<DomainIcon />}
                endDecorator={
                  <Button
                    onClick={() => storeOrganizationName(organizationName)}
                    disabled={!validId || loading || organizationName === ""}
                  >
                    Save
                  </Button>
                }
                placeholder="My Org"
                value={organizationName}
                onChange={(event) => setOrganizationName(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validId ? "" : "Invalid value"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>

        {/* <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={!validId || loading || organizationName === ""}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid> */}
      </Grid>
    </>
  );
};
