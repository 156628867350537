import { useContext } from "react";
import { MyTeamComponent } from "./component";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";
import {
  useGetMyTeamMembersQuery,
  useInviteMyTeamMemberMutation,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export const MyTeamContainer = (props: {}) => {
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: getMyTeamMembersData, loading: getMyTeamMembersLoading } =
    useGetMyTeamMembersQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const navigate = useNavigate();

  const navigateToEditUser = (userId: string) => {
    navigate(`/my_team/${userId}`, { replace: true });
  };

  const [inviteMyTeamMember, { loading: inviteMyTeamMemberLoading }] =
    useInviteMyTeamMemberMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const inviteWithEmail = async (email: string) => {
    if (email === "") {
      addAlert({
        text: "Please specify the email address that you want to send an invite to",
        type: AlertType.DANGER,
      });
      return;
    }

    await inviteMyTeamMember({
      variables: {
        email,
      },
      optimisticResponse: () => ({
        inviteMyTeamMember: {
          id: email,
          name: "",
          email,
          status: "PENDING",
        },
      }),
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyTeamMembers(existingUsers = []) {
              const newUserRef = cache.writeFragment({
                data: data?.inviteMyTeamMember,
                fragment: gql`
                  fragment NewUser on User {
                    id
                    name
                    email
                    status
                  }
                `,
              });
              return [...existingUsers, newUserRef];
            },
          },
        });
      },
      refetchQueries: ["getMyTeamMembers"],
      onCompleted: (data) => {
        if (data?.inviteMyTeamMember?.email) {
          navigateToEditUser(data?.inviteMyTeamMember?.email);
        }
      },
    });
  };

  return (
    <MyTeamComponent
      loading={getMyTeamMembersLoading || inviteMyTeamMemberLoading}
      users={getMyTeamMembersData?.getMyTeamMembers || []}
      inviteWithEmail={inviteWithEmail}
      navigateToEditUser={navigateToEditUser}
    />
  );
};
