import { useNavigate, useParams } from "react-router-dom";
import { OrganizationSetupContainer } from "./container";
import { ORG_STEPS } from "./steps";
import { useEffect } from "react";

export const OrganizationSetupPage = () => {
  const params = useParams();
  const { stepId } = params;

  const navigate = useNavigate();
  const validStep = ORG_STEPS.map((s) => s.id).includes(stepId || "");
  useEffect(() => {
    if (!validStep) {
      navigate(`/organization_setup/${ORG_STEPS[0].id}`, {
        replace: true,
      });
    }
  }, [validStep, navigate]);
  if (!validStep) {
    return null;
  }
  return <OrganizationSetupContainer stepId={stepId!} />;
};
