import { PageWithRightPane } from "../../layouts/page_with_right_pane";
import { MyTeamContainer } from "./container";

export const MyTeamPage = (props: {}) => {
  return (
    <PageWithRightPane>
      <MyTeamContainer />
    </PageWithRightPane>
  );
};
