import { useNavigate } from "react-router-dom";
import { OrganizationSetupComponent } from "./component";
import { ORG_STEPS } from "./steps";
import { StepperComponent } from "./stepper";

export const OrganizationSetupContainer = (props: { stepId: string }) => {
  const { stepId } = props;
  const stepDef = ORG_STEPS.find((s) => s.id === stepId);
  const stepIx = ORG_STEPS.findIndex((s) => s.id === stepId);

  const navigate = useNavigate();
  let navigateToPrevious: (() => void) | null = null;
  if (stepIx > 0) {
    navigateToPrevious = () =>
      navigate(`/organization_setup/${ORG_STEPS[stepIx - 1].id}`, {
        replace: true,
      });
  }
  let navigateToNext: (() => void) | null = null;
  if (stepIx < ORG_STEPS.length - 1) {
    navigateToNext = () =>
      navigate(`/organization_setup/${ORG_STEPS[stepIx + 1].id}`, {
        replace: true,
      });
  }
  const navigateToStep = (stepId: string) => {
    navigate(`/organization_setup/${stepId}`, {
      replace: true,
    });
  };
  const navigateWhenFinished = () => {
    navigate(`/onboarding`, {
      replace: true,
    });
  };
  const StepComponent = stepDef!.component;

  return (
    <OrganizationSetupComponent
      loading={false}
      stepComponent={
        <StepComponent
          navigateToNext={navigateToNext}
          navigateToPrevious={navigateToPrevious}
          navigateWhenFinished={navigateWhenFinished}
        />
      }
      stepperComponent={
        <StepperComponent stepId={stepId} navigateToStep={navigateToStep} />
      }
    />
  );
};
