import { OrganizationNameContainer } from "./organization_name/container";
import { InviteTeamContainer } from "./invite_team/container";

export type StepDef = {
  id: string;
  title: string;
  component: (props: {
    navigateToNext: null | (() => void);
    navigateToPrevious: null | (() => void);
    navigateWhenFinished: () => void;
  }) => JSX.Element;
};

export const ORG_STEPS: StepDef[] = [
  {
    id: "organization_name",
    title: "My Organization",
    component: OrganizationNameContainer,
  },
  {
    id: "invite_team",
    title: "My Team",
    component: InviteTeamContainer,
  },
];
