import { useContext } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AddNewDeploymentComponent } from "./component";
import {
  useAddMyDeploymentMutation,
  useGetMyDeploymentsQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { gql } from "@apollo/client";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const AddNewDeploymentContainer = (props: {
  navigateToNext: null | ((deploymentId: string) => void);
}) => {
  const { navigateToNext } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const [addMyDeploymentMutation, { loading: addMyDeploymentMutationLoading }] =
    useAddMyDeploymentMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const addDeployment = (
    accountId: string,
    awsRegion: string,
    deploymentId: string,
    deploymentName: string
  ) => {
    addMyDeploymentMutation({
      variables: {
        deploymentInput: {
          id: deploymentId,
          name: deploymentName,
          accountId,
          awsRegion,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyDeployments(existingDeployments = []) {
              const newDeploymentRef = cache.writeFragment({
                data: data?.addMyDeployment,
                fragment: gql`
                  fragment NewDeployment on Deployment {
                    id
                    name
                    accountId
                    awsRegion
                  }
                `,
              });
              return [...existingDeployments, newDeploymentRef];
            },
          },
        });
      },
      refetchQueries: ["GetMyDeployments"],
      onCompleted: (data) => {
        if (data?.addMyDeployment !== undefined && navigateToNext) {
          navigateToNext(data!.addMyDeployment!.id);
        }
      },
    });
  };

  if (getMyDeploymentsError) {
    console.error(getMyDeploymentsError);
  }

  return (
    <AddNewDeploymentComponent
      loading={getMyDeploymentsLoading || addMyDeploymentMutationLoading}
      deployments={getMyDeploymentsData?.getMyDeployments || []}
      addDeployment={addDeployment}
    />
  );
};
