import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  Deployment,
  useCreateMyCertificateCnameRecordMutation,
  useGetMyCertificateCnameRecordStatusLazyQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { StackSummaryComponent } from "./stack_summary";

export const CertCnameRecordDeployer = (props: {
  deploymentId: string;
  deployment: Deployment | null;
  deploymentEnabled: boolean;
  setStackCompleted: (status: boolean) => void;
}) => {
  const { deploymentId, deploymentEnabled, setStackCompleted } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const [changeId, setChangeId] = useState<string | null>(null);
  const [recordAlreadyExists, setRecordAlreadyExists] =
    useState<boolean>(false);

  const [
    getMyCertificateCnameRecordStatus,
    {
      data: getMyCertificateCnameRecordStatusData,
      loading: getMyCertificateCnameRecordStatusLoading,
      refetch: getMyCertificateCnameRecordStatusRefetch,
    },
  ] = useGetMyCertificateCnameRecordStatusLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const status =
    getMyCertificateCnameRecordStatusData?.getMyCertificateCnameRecordStatus;

  const [triggeringStack, setTriggeringStack] = useState<boolean>(false);

  const [
    createMyCertificateCnameRecord,
    { loading: createMyCertificateCnameRecordLoading },
  ] = useCreateMyCertificateCnameRecordMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (
        // "message": "[Tried to create resource record set [name='_8abda848fe0727474d2316fd8b5c09a3.demo-prod.app.wafflecode.com.', type='CNAME'] but it already exists]",
        error.message.indexOf(", type='CNAME'] but it already exists]") > -1
      ) {
        setRecordAlreadyExists(true);
      } else if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
    onCompleted: (data) => {
      setChangeId(data?.createMyCertificateCnameRecord || null);
      setTimeout(async () => {
        // setTriggeringStack(false);
        if (changeId) {
          getMyCertificateCnameRecordStatus({
            variables: {
              deploymentId,
              changeInfoId: changeId,
            },
          });
        }
      }, 3000);
    },
  });

  // Start deployment
  useEffect(() => {
    if (!triggeringStack && deploymentEnabled) {
      setTriggeringStack(true);
      createMyCertificateCnameRecord({
        variables: {
          deploymentId,
        },
      });
    }
  }, [
    deploymentEnabled,
    createMyCertificateCnameRecord,
    deploymentId,
    getMyCertificateCnameRecordStatusData,
    triggeringStack,
  ]);

  // Check deployment progress
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (changeId) {
        getMyCertificateCnameRecordStatusRefetch({
          deploymentId,
          changeInfoId: changeId,
        });
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [deploymentId, getMyCertificateCnameRecordStatusRefetch, changeId]);

  // Callback
  useEffect(() => {
    setStackCompleted(recordAlreadyExists || status === "DONE");
  }, [status, setStackCompleted, recordAlreadyExists]);

  return (
    <StackSummaryComponent
      loading={
        getMyCertificateCnameRecordStatusLoading ||
        createMyCertificateCnameRecordLoading
      }
      deploymentEnabled={deploymentEnabled}
      title="SSL Certificate validation"
      link={null}
      status={
        status ||
        (status === undefined ? undefined : !status ? "TO_BE_DONE" : status)
      }
    />
  );
};
