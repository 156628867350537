import { useContext } from "react";
import { EditUserComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { useGetMyTeamMembersQuery } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const EditUserContainer = (props: { userId: string }) => {
  const { userId } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: getMyTeamMembersData, loading: getMyTeamMembersLoading } =
    useGetMyTeamMembersQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const navigate = useNavigate();

  const navigateToMyTeam = () => {
    navigate(`/my_team`, { replace: true });
  };

  const user =
    (getMyTeamMembersData?.getMyTeamMembers || []).find(
      (u) => u.id === userId
    ) || null;

  return (
    <EditUserComponent
      loading={getMyTeamMembersLoading}
      user={user}
      navigateToMyTeam={navigateToMyTeam}
    />
  );
};
