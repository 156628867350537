import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { User } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageLoading } from "../../../components/page_loading";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";

export const EditUserComponent = (props: {
  loading: boolean;
  user: User | null;
  navigateToMyTeam: () => void;
}) => {
  const { loading, user, navigateToMyTeam } = props;
  return (
    <RightPaneFrame>
      <RightPaneBackButton onClick={navigateToMyTeam} labelText="Close" />
      <RightPaneTitle>Team member</RightPaneTitle>
      <PageLoading loading={loading} />

      {user && (
        <>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input value={user.email || ""} disabled={true} />
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <Input value={user.status || ""} disabled={true} />
          </FormControl>
        </>
      )}
    </RightPaneFrame>
  );
};
