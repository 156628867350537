import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageLoading } from "../../../components/page_loading";

export const DeployDeploymentStacksComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  vpcDeployer: ReactNode;
  apiDeployer: ReactNode;
  deploymentDeployer: ReactNode;
  alertsDeployer: ReactNode;
  hostedZoneDeployer: ReactNode;
  nsRecordDeployer: ReactNode;
  certDeployer: ReactNode;
  certCnameRecordDeployer: ReactNode;
  percentDone: number;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  deploymentEnabled: boolean;
  setDeploymentEnabled: () => void;
  deploymentCompleted: boolean;
}) => {
  const {
    loading,
    vpcDeployer,
    apiDeployer,
    deploymentDeployer,
    alertsDeployer,
    hostedZoneDeployer,
    nsRecordDeployer,
    certDeployer,
    certCnameRecordDeployer,
    percentDone,
    navigateToNext,
    navigateToPrevious,
    deploymentEnabled,
    setDeploymentEnabled,
    deploymentCompleted,
  } = props;

  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12} sx={{ pb: 2 }}>
          <Typography level="h2" sx={{ pb: 8 }}>
            Deploying a Secure Environment
          </Typography>
          <Typography level="body-md" sx={{ pb: 1 }}>
            This step is about creating resources in your AWS account.
          </Typography>
          <Typography level="body-md" sx={{ pb: 2 }}>
            By clicking the button below, Waffle will start deploying the stacks
            and resources listed below.
          </Typography>
          <Button
            size="lg"
            disabled={loading || deploymentEnabled || deploymentCompleted}
            onClick={() => setDeploymentEnabled()}
          >
            Start deployment
          </Button>
        </Grid>
        <Grid xs={12}>
          <LinearProgress determinate value={percentDone} />
        </Grid>

        {vpcDeployer}
        {deploymentDeployer}
        {alertsDeployer}
        {hostedZoneDeployer}
        {nsRecordDeployer}
        {certDeployer}
        {certCnameRecordDeployer}
        {apiDeployer}

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={!deploymentCompleted}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
