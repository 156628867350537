import Grid from "@mui/joy/Grid";
import { ReactNode } from "react";
import { PageLoading } from "../../components/page_loading";
import { LeanLayout } from "./layout";

export const OrganizationSetupComponent = (props: {
  loading: boolean;
  stepComponent: ReactNode | null;
  stepperComponent: ReactNode | null;
}) => {
  const { loading, stepComponent, stepperComponent } = props;
  return (
    <LeanLayout>
      <PageLoading loading={loading} />
      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          pt: 4,
          mt: 1,
        }}
      >
        <Grid xs={12} sm={12} sx={{ mb: 4 }}>
          {stepperComponent}
        </Grid>
        <Grid xs={12} sm={12}>
          {stepComponent}
        </Grid>
      </Grid>
    </LeanLayout>
  );
};
