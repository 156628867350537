import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  Deployment,
  useGetMyCertificateQuery,
  useRequestMyCertificateMutation,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { StackSummaryComponent } from "./stack_summary";

export const CertDeployer = (props: {
  deploymentId: string;
  deployment: Deployment | null;
  deploymentEnabled: boolean;
  setStackCompleted: (status: boolean) => void;
}) => {
  const { deploymentId, deployment, deploymentEnabled, setStackCompleted } =
    props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyCertificateData,
    loading: getMyCertificateLoading,
    refetch: getMyCertificateRefetch,
  } = useGetMyCertificateQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const status = getMyCertificateData?.getMyCertificate?.status;
  const certificateArn = getMyCertificateData?.getMyCertificate?.arn;

  const [triggeringStack, setTriggeringStack] = useState<boolean>(false);

  const [requestMyCertificate, { loading: requestMyCertificateLoading }] =
    useRequestMyCertificateMutation({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
      onCompleted: () => {
        setTimeout(async () => {
          await getMyCertificateRefetch();
          // setTriggeringStack(false);
        }, 3000);
      },
    });

  // Start deployment
  useEffect(() => {
    if (status === null && !triggeringStack && deploymentEnabled) {
      setTriggeringStack(true);
      requestMyCertificate();
    }
  }, [status, deploymentEnabled, requestMyCertificate, triggeringStack]);

  // Check deployment progress
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!status || !["ISSUED", "INACTIVE"].includes(status || "")) {
        getMyCertificateRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [status, getMyCertificateRefetch]);

  // Callback
  useEffect(() => {
    setStackCompleted(
      ["PENDING_VALIDATION", "ISSUED", "INACTIVE"].includes(status || "")
    );
  }, [status, setStackCompleted]);

  const link = `https://${
    deployment?.awsRegion
  }.console.aws.amazon.com/acm/home?region=${
    deployment?.awsRegion
  }#/certificates/${certificateArn?.split("/")[1]}`;

  return (
    <StackSummaryComponent
      loading={getMyCertificateLoading || requestMyCertificateLoading}
      deploymentEnabled={deploymentEnabled}
      title="SSL Certificate"
      link={link}
      status={status === null ? "TO_BE_REQUESTED" : status}
    />
  );
};
