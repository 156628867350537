import Security from "@mui/icons-material/Security";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Snackbar from "@mui/joy/Snackbar";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { ServiceStack } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageFrame } from "../../../components/page_frame";
import { PageTitle } from "../../../components/page_title";
import { PageLoading } from "../../../components/page_loading";

export const AddNewServiceComponent = (props: {
  loading: boolean;
  stacks: ServiceStack[];
  addStackError: string | null;
  resetAddStackError: () => void;
  navigateToEditStack: (stackId: string) => void;
  addStackId: string;
  setAdddStackId: React.Dispatch<React.SetStateAction<string>>;
  addStackType: string;
  setAdddStackType: React.Dispatch<React.SetStateAction<string>>;
  addStack: (stackId: string) => void;
  canAddService: boolean;
}) => {
  const {
    loading,
    stacks,
    addStackError,
    resetAddStackError,
    // navigateToEditStack,
    addStackId,
    setAdddStackId,
    addStackType,
    setAdddStackType,
    addStack,
    canAddService,
  } = props;
  const validId =
    addStackId === "" ||
    (stacks.map((s) => s.id).indexOf(addStackId) === -1 &&
      /^[a-z,0-9]+$/.test(addStackId));
  return (
    <PageFrame>
      <PageTitle>Create a New Service</PageTitle>
      <Typography>
        A services is a set of AWS resources including a CICD pipeline and some
        kind of a server that runs a backend code. These resources are deployed
        as a CloudFormation stack.
      </Typography>
      <PageLoading loading={loading} />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={addStackError !== null}
        onClose={resetAddStackError}
      >
        {addStackError}
      </Snackbar>

      <Grid container spacing={2} sx={{ flexGrow: 1, pt: 4 }}>
        <Grid xs={12} sm={6}>
          <Typography level="h3" sx={{ pb: 2 }}>
            Choose a Service ID
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            The stack id can be any string of your choice. It refers to your
            service. You can use this id from your code to find this service.
            Typical examples would be{" "}
            <Typography variant="outlined">engine</Typography> or{" "}
            <Typography variant="outlined">api</Typography>.
          </Typography>
          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>New Service ID</FormLabel>
            <Stack spacing={0.5}>
              <Input
                startDecorator={<Security />}
                placeholder="eg. engine"
                value={addStackId}
                onChange={(event) => setAdddStackId(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validId ? "" : "Invalid value"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography level="h3" sx={{ pb: 2 }}>
            Select a Service Type
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            You can select from 3 stack types: frontend, dockerized backend and
            SQL database. If you need a different type of stack, please don't
            hesitate to reach out to the Waffle team, there is likely a quick
            and feasible solution for you.
          </Typography>

          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>New Service Type</FormLabel>
            <Select
              placeholder="Select Service Type"
              value={addStackType}
              slotProps={{
                listbox: {
                  sx: {
                    // maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              <Option
                value="ECS"
                onClick={() => {
                  setAdddStackType("ECS");
                }}
              >
                Dockerized Backend Service
              </Option>
              <Option
                value="CDN"
                onClick={() => {
                  setAdddStackType("CDN");
                }}
              >
                Web Frontend
              </Option>
              <Option
                value="CFN"
                onClick={() => {
                  setAdddStackType("CFN");
                }}
              >
                Custom CloudFormation Stack
              </Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Button
            onClick={() => {
              addStack(addStackId);
            }}
            disabled={!validId || addStackId === "" || !canAddService}
          >
            Create Service
          </Button>
        </Grid>
      </Grid>
    </PageFrame>
  );
};
