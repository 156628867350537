import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input/Input";
import Table from "@mui/joy/Table";
import { useState } from "react";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import { TableFrame } from "../../components/table_frame";
import { User } from "../../services/backend_gateway/__generated__/backend_gateway-types";

export const MyTeamComponent = (props: {
  loading: boolean;
  users: User[];
  inviteWithEmail: (email: string) => Promise<void>;
  navigateToEditUser: (userId: string) => void;
}) => {
  const { loading, users, inviteWithEmail, navigateToEditUser } = props;
  const [newEmail, setNewEmail] = useState<string>("");
  const validEmail =
    newEmail === "" || !users.map((u) => u.email || "").includes(newEmail);
  return (
    <PageFrame>
      <PageTitle>My Team</PageTitle>
      <PageLoading loading={loading} />

      <TableFrame cellHeight={40} tableHeight={200}>
        <Table hoverRow stickyHeader>
          <thead>
            <tr>
              <th style={{ width: "250" }}>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} onClick={() => navigateToEditUser(user.id)}>
                <td>{user.email}</td>
                <td>{user.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableFrame>

      <FormControl>
        <FormLabel>Invite a Team Member</FormLabel>
        <Input
          startDecorator={<PersonIcon />}
          endDecorator={
            <Button
              onClick={() => inviteWithEmail(newEmail)}
              disabled={!validEmail || loading || newEmail === ""}
            >
              Invite
            </Button>
          }
          placeholder="email address"
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
        />
        <FormHelperText>
          An automated email will be sent with instructions how to join
        </FormHelperText>
      </FormControl>
    </PageFrame>
  );
};
