import { useContext, useEffect, useState } from "react";
import { DeploymentStepsApiComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useDeployMyApiStackMutation,
  useFindMyApiStackValuesQuery,
  useGetMyDeploymentsQuery,
  useGetMyApiStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsApiContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const {
    data: findMyApiStackValuesData,
    loading: findMyApiStackValuesLoading,
    refetch: findMyApiStackValuesRefetch,
  } = useFindMyApiStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const {
    data: getMyApiStackStatusData,
    loading: getMyApiStackStatusLoading,
    error: getMyApiStackStatusError,
    refetch: getMyApiStackStatusRefetch,
  } = useGetMyApiStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const [deployMyApiStack, { loading: deployMyApiStackLoading }] =
    useDeployMyApiStackMutation({
      client: bgwService.getClient(),
    });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  useEffect(() => {
    if ((getMyApiStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyApiStackStatusError!.message]);
    }
  }, [getMyApiStackStatusError]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (getMyApiStackStatusData?.getMyApiStackStatus?.status || "").indexOf(
          "IN_PROGRESS"
        ) > -1
      ) {
        getMyApiStackStatusRefetch();
        findMyApiStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    getMyApiStackStatusData,
    getMyApiStackStatusRefetch,
    findMyApiStackValuesRefetch,
  ]);

  const [disableDeployButton, setDisableDeployButton] =
    useState<boolean>(false);

  const deployStack = (excludeStageFromPath: boolean) => {
    setDisableDeployButton(true);
    deployMyApiStack({
      variables: {
        apiStackInput: {
          deploymentId,
          excludeStageFromPath,
        },
      },
      onCompleted: (data) => {
        setTimeout(checkStackStatus, 3000);
      },
      onError: (error) => {
        setQueryError((e) => [...e, `Failed deploy stack.`]);
        setDisableDeployButton(false);
        console.error(error);
      },
    });
  };

  const checkStackStatus = () => {
    getMyApiStackStatusRefetch();
    findMyApiStackValuesRefetch();
    setTimeout(() => {
      setDisableDeployButton(false);
    }, 3000);
  };

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  return (
    <DeploymentStepsApiComponent
      loading={
        getMyDeploymentsLoading ||
        findMyApiStackValuesLoading ||
        getMyApiStackStatusLoading ||
        deployMyApiStackLoading
      }
      deployment={deployment || null}
      queryError={queryError}
      removeQueryError={removeQueryError}
      checkStackStatus={checkStackStatus}
      stackId={findMyApiStackValuesData?.findMyApiStackValues || null}
      stackStatus={getMyApiStackStatusData?.getMyApiStackStatus || null}
      deployStack={deployStack}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      disableDeployButton={disableDeployButton}
    />
  );
};
