import ConstructionIcon from "@mui/icons-material/Construction";
import PublicIcon from "@mui/icons-material/Public";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import { awsRegions } from "../../../configs/aws_regions";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { LeanLayout } from "../layout";

export const AddNewDeploymentComponent = (props: {
  loading: boolean;
  deployments: Deployment[];
  addDeployment: (
    accountId: string,
    awsRegion: string,
    deploymentId: string,
    deploymentName: string
  ) => void;
}) => {
  const { loading, addDeployment } = props;

  const [deploymentId, setDeploymentId] = useState<string>("prod");
  const [accountId, setAccountId] = useState<string>("");
  const [awsRegion, setAwsRegion] = useState<string>("us-east-1");

  return (
    <LeanLayout>
      <PageLoading loading={loading} />

      <Typography level="h2" sx={{ pb: 2 }}>
        Deploy a SOC 2 compliant web-server
      </Typography>
      <Typography level="body-md" sx={{ py: 1 }}>
        Let's start with deploying your web-server, hosted privately, securely,
        including a CICD pipeline in your AWS account.
      </Typography>

      <FormControl sx={{ pt: 2 }}>
        <FormLabel>Your AWS Account #</FormLabel>
        <Input
          value={accountId}
          onChange={(event) => {
            setAccountId(event.target.value);
          }}
        />
        <FormHelperText>
          The ID of the AWS account you're planning to deploy into. If you're
          deploying to a sub-account of an AWS organization, then specify the
          sub-account's ID.
        </FormHelperText>
      </FormControl>

      <FormControl sx={{ pt: 2 }}>
        <FormLabel>AWS Region</FormLabel>
        <Select
          value={awsRegion}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null
          ) => {
            newValue && setAwsRegion(newValue!);
          }}
        >
          {awsRegions.map((r) => (
            <Option value={r} key={r}>
              {r}
            </Option>
          ))}
        </Select>
        <FormHelperText>
          Select which AWS region shall your web-server live in
        </FormHelperText>
      </FormControl>

      <FormControl sx={{ pt: 2 }}>
        <FormLabel>Deployment Purpose</FormLabel>
        <Select
          value={deploymentId}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null
          ) => {
            newValue && setDeploymentId(newValue!);
          }}
        >
          <Option value="prod">
            <ListItemDecorator>
              <Avatar size="sm">
                <PublicIcon />
              </Avatar>
            </ListItemDecorator>
            Production
          </Option>
          <Option value="dev">
            <ListItemDecorator>
              <Avatar size="sm">
                <ConstructionIcon />
              </Avatar>
            </ListItemDecorator>
            Development
          </Option>
        </Select>
        <FormHelperText>
          Chooses default settings that you can change anytime later.
        </FormHelperText>
      </FormControl>

      <Button
        sx={{ mt: 4 }}
        onClick={() =>
          addDeployment(
            accountId,
            awsRegion,
            deploymentId,
            deploymentId === "dev" ? "Development" : "Production"
          )
        }
      >
        Let's go
      </Button>
    </LeanLayout>
  );
};
