import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DomainIcon from "@mui/icons-material/Domain";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import { User } from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const InviteTeamComponent = (props: {
  loading: boolean;
  users: User[];
  inviteWithEmail: (email: string) => Promise<void>;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
}) => {
  const {
    loading,
    users,
    inviteWithEmail,
    navigateToNext,
    navigateToPrevious,
  } = props;

  const [newEmail, setNewEmail] = useState<string>("");

  const validEmail =
    newEmail === "" || !users.map((u) => u.email || "").includes(newEmail);
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Invite your team members
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            You can send out invitations to your team members' email addresses.
            They will be assigned to your organization.
          </Typography>
        </Grid>

        {users.length > 0 && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ pb: 2 }}>
              Invites has been sent to:
            </Typography>
            {users.map((user) => (
              <Typography level="body-md" sx={{ pb: 1 }}>
                {user.email}
              </Typography>
            ))}
          </Grid>
        )}

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Email address of your team member to invite</FormLabel>
            <Stack spacing={0.5}>
              <Input
                startDecorator={<DomainIcon />}
                endDecorator={
                  <Button
                    onClick={async () => {
                      await inviteWithEmail(newEmail);
                      setNewEmail("");
                    }}
                    disabled={!validEmail || loading}
                  >
                    Invite
                  </Button>
                }
                placeholder="email address"
                value={newEmail}
                onChange={(event) => setNewEmail(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validEmail ? "" : "Invalid email address"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={loading}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
